import * as React from "react"
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => {
  return (
    <Layout>
      <Seo 
        title="404" 
        description="The charge you seek has faded"
      />
      <div className="flex flex-col w-full mx-4 mt-10 lg:max-w-screen-lg lg:mx-auto">
        <h1 className="text-5xl font-extrabold text-center">Have you tried turning it off and on again?</h1>
        <StaticImage 
            placeholder="tracedSVG"
            src="../images/404.png" 
            alt="Whoops something went wrong there"/>
      </div>
    </Layout>
  )
}

export default NotFoundPage
